<template>
  <div class="vg_wrapper">
    <EditHeader
      :isShow="isShow"
      :btn="btn"
      :special="true"
      :strForm="{ status: formStatus }"
      @openEdit="openEdit"
      @closeEdit="closeEdit"
      @submit="submit"
      @refresh="initData"
    >
      <div slot="functionButton">
        <el-tooltip v-if="mtrb_start === 0" content="数量更改或取消订单使用" effect="dark" placement="top">
          <el-button class="vg_ml_8" size="small" type="danger" @click="cancelInspection">取消验货</el-button>
        </el-tooltip>
      </div>
      <div slot="otherButton">
        <div class="flexHV vg_mr_8">
          <el-tag v-if="mtrb_start === 0" class="vg_mr_8" size="mini" type="success">正常验货</el-tag>
          <el-tag v-else class="vg_mr_8" size="mini" type="danger"> 取消验货</el-tag>
          <span>申请单据号:</span>
          <el-input disabled size="small" :value="inspecationmtrb_no" style="width: 160px"></el-input>
        </div>
      </div>
      <div slot="specialStatus" style="display: flex">
        <div class="flexHV">
          <span>状态：</span>
          <el-tag :type="helper.getStatusName(formStatus).type">{{ helper.getStatusName(formStatus).name }}</el-tag>
        </div>
      </div>
    </EditHeader>
    <DynamicForm
      v-bind="$attrs"
      :total-width="mainForm.totalWidth"
      :split="mainForm.split"
      :form-list="mainForm.formProperties"
      ref="dynamicForm"
      class="dynamicForm"
      :is-show="isShow"
      :computed-data="{ mtrb_num: mtrb_num, inspecationmtrb_num: inspecationmtrb_num }"
    />
    <div>
      <el-button :disabled="isShow" class="vg_mb_8" type="danger" size="small" @click="delRow()">删除</el-button>
    </div>
    <DynamicUTable
      ref="dynamicUTable"
      :tableData="subTableData"
      :columns="applicationEditSubTableProperties"
      :need-pagination="false"
      :need-search="false"
      :is-show="isShow"
      @selection-change="val => (subSelections = val)"
    />
    <el-row class="vg_mt_16 vd_bortop">
      <el-col>
        <inputUser :isCookie="false" :stffForm="stffForm" ref="userData"></inputUser>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import EditHeader from '@/views/component/editHeaderLine.vue';
import inputUser from '@/views/component/inputUser';
import DynamicForm from '@/components/DynamicFormV2.vue';
import { cloneDeep } from 'lodash';
import { applicationEditSubTableProperties, applicationMainFormProperties } from '@/views/InspectionManagement/InspectionApplicationMtrbManage/applicationMtrb';
import DynamicUTable from '@/components/table/DynamicUTable.vue';
import { applicationMtrb } from '@api/modules/applicationMtrb';
import { requiredReminderAndUGrid } from '@assets/js/domUtils';
import { deleteTableRow, objectArrayReduce } from '@assets/js/arrayUtils';
import { changeStaffForm, getDataCenterByPermId, getStff } from '@api/public';
import UrlEncode from '@assets/js/UrlEncode';

export default {
  name: 'applicationEditMain',
  components: {
    DynamicUTable,
    DynamicForm,
    EditHeader,
    inputUser
  },
  data() {
    return {
      isShow: true,
      btn: {},
      stffForm: {},
      mainForm: {
        split: 8,
        totalWidth: 24,
        formProperties: cloneDeep(applicationMainFormProperties)
      },
      subTableData: [],
      subSelections: [],
      subTableMap: new Map(),
      applicationEditSubTableProperties: cloneDeep(applicationEditSubTableProperties),
      formStatus: 0,
      inspecationmtrb_no: '',
      inspecationmtrb_id: '',
      mtrb_start: '',
      order_type: '',
      rateList: []
    };
  },
  computed: {
    mtrb_num() {
      let val = objectArrayReduce(this.subTableData, 'mtrb_num', 2);
      let find = this.mainForm.formProperties.find(({ prop }) => prop === 'mtrb_num');
      if (isNaN(val)) {
        find.model = 0;
        return '自动计算';
      }
      find.model = val;
      return val;
    },
    inspecationmtrb_num() {
      let val = objectArrayReduce(this.subTableData, 'goods_inspected', 2);
      let find = this.mainForm.formProperties.find(({ prop }) => prop === 'inspecationmtrb_num');
      if (isNaN(val)) {
        find.model = 0;
        return '自动计算';
      }
      find.model = val;
      return val;
    }
  },
  async mounted() {
    await this.initData();
    await this.changeProperties();
  },
  methods: {
    async initData() {
      this.getApplicationData();
      this.rateList = await getDataCenterByPermId({ id: 10046 });
    },
    async changeProperties() {
      this.mainForm.formProperties.find(({ prop }) => prop === 'inspecationmtrb_stff_id').options = [...(await getStff('质检')), ...(await getStff('QC'))];
      let find = this.mainForm.formProperties.find(({ prop }) => prop === 'inspecationmtrb_stff_aid');
      find.options = await getStff();
      find.model = Number(this.$cookies.get('userInfo').stff_id);
      this.applicationEditSubTableProperties.find(({ prop }) => prop === 'goods_inspected').subItem.change = (val, row, options) => {
        this.rateList.forEach(item => {
          if (val >= Number(item.param1) && val < Number(item.param2)) this.$set(row, 'sampling_quantity', Number(item.param3));
        });
      };
    },
    //启用编辑
    openEdit() {
      this.isShow = false;
      this.$emit('isShow', this.isShow);
    },
    closeEdit() {
      this.$confirm('是否撤销编辑?', '提示', {
        confirmButtonText: '是',
        cancelButtonText: '否',
        type: 'warning'
      })
        .then(() => {
          this.isShow = true;
          this.$emit('isShow', this.isShow);
          this.initData();
          this.$message({
            type: 'info',
            message: '已撤销编辑!'
          });
        })
        .catch(() => {});
    },
    getApplicationData() {
      const props = JSON.parse(UrlEncode.decode(this.$route.query.key));
      applicationMtrb.getApplicationById({ inspecationmtrb_id: props.form_id }).then(({ data }) => {
        this.btn = data.btn;
        this.inspecationmtrb_no = data.form.inspecationmtrb_no;
        this.inspecationmtrb_id = data.form.inspecationmtrb_id;
        this.mtrb_start = data.form.mtrb_start;
        this.order_type = data.form.order_type;
        this.formStatus = data.form.status;
        changeStaffForm(this.stffForm, data.form);
        this.mainForm.formProperties.forEach(item => (item.model = data.form[item.prop]));
        this.subTableData = data.form.inspecationMtrbList;
      });
    },
    submit() {
      this.$confirm('确认保存?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(async () => {
          let dynamicUTable = await this.$refs.dynamicUTable.fullValidateMsg();
          this.$refs.dynamicForm.$refs.form.validate((valid, object) => {
            if (valid && !dynamicUTable) {
              this.saveInfo();
            } else {
              let tempStr = '';
              if (dynamicUTable) tempStr += `子表-${dynamicUTable}`;
              requiredReminderAndUGrid(object, tempStr);
            }
          });
        })
        .catch(err => console.error(err));
    },
    saveInfo() {
      applicationMtrb.editInspecationMtrb(this.getSaveData()).then(({ data }) => {
        this.$message.success('保存成功!');
        this.initData();
        this.isShow = true;
        this.$emit('isShow', this.isShow);
      });
    },
    getSaveData() {
      let formModel = this.$refs.dynamicForm.getFormModel();
      formModel.inspecationmtrb_id = this.inspecationmtrb_id;
      formModel.inspecationmtrb_no = this.inspecationmtrb_no;
      formModel.mtrb_start = this.mtrb_start;
      formModel.status = this.formStatus;
      formModel.order_type = this.order_type;
      formModel.inspecationMtrbList = this.subTableData.concat(Array.from(this.subTableMap.values()));
      return formModel;
    },
    delRow() {
      let { tableData, map } = deleteTableRow('inspecation_mtrb_id', this.subTableData, this.subTableMap, this.subSelections);
      this.subTableData = tableData;
      this.subTableMap = map;
    },
    cancelInspection() {
      this.$confirm('是否确认取消?', '提示', {
        confirmButtonText: '是',
        cancelButtonText: '否',
        type: 'warning'
      })
        .then(() => {
          applicationMtrb.editInspecationMtrbStart({ inspecationmtrb_id: this.inspecationmtrb_id }).then(() => {
            this.$message.success('取消成功!');
            this.initData();
          });
        })
        .catch(err => console.error(err));
    }
  }
};
</script>

<style scoped lang="scss"></style>
